export const app = [`1 App`, `3 Apps`, `5 Apps`, `10 Apps`, `15 Apps`, `20 Apps`];

export const desktop = [
  `1 User`,
  `5 Users`,
  `10 Users`,
  `15 Users`,
  `20 Users`,
  `25 Users`,
  `30 Users`,
  `50 Users`,
  `75 Users`,
  `100 Users`,
  `150 Users`,
  `200 Users`,
  `500 Users`,
];

export const ebook = [`1 Ebook`, `2 Ebooks`, `5 Ebooks`, `10 Ebooks`];

export const web = [
  `10,000 Views/Month`,
  `100,000 Views/Month`,
  `500,000 Views/Month`,
  `1,000,000 Views/Month`,
  `2,500,000 Views/Month`,
  `5,000,000 Views/Month`,
  `10,000,000 Views/Month`,
  `25,000,000 Views/Month`,
];
