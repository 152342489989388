/** @jsx jsx */
import { jsx, Container, Flex, Text } from 'theme-ui';

import React, { useMemo, useCallback } from 'react';
import { Stack } from '../grid';

export const ProductItem = (props) => {
  const {
    id,
    guid,
    title,
    description,
    licence,
    licenceTitle,
    licenceCategory,
    variants,
    typeface,
    isFamily,
  } = props;

  // const prices = variants.reduce((res, cur, index) => {
  //   const t_discount = licence[`t${index}_discount`];
  //   const t_price = licence[`t${index}_value`];

  //   res.push({ discount: t_discount, price: t_price });

  //   return res;
  // }, []);

  const options = variants.reduce((res, cur, index) => {
    // const price = index === 0 ? `` : `+${licence[`t${index}_value`]}`;

    const t_discount = licence[`t${index}_discount`];
    const t_price = licence[`t${index}_value`];

    const price = t_discount || t_price;

    let result = res + `${cur}[${price}]`;
    result += index < variants.length - 1 ? '|' : '';
    return result;
  }, '');

  const categories = useMemo(() => {
    const familyOrStyleCategory = isFamily ? 'family' : 'style';
    const arrayOfCategories = [
      typeface,
      licenceCategory,
      familyOrStyleCategory,
      `${typeface}-${familyOrStyleCategory}`,
      `${typeface}-${licenceCategory}`,
      `${typeface}-${familyOrStyleCategory}-${licenceCategory}`,
    ];

    return arrayOfCategories.join('|');
  }, [typeface, licenceCategory, isFamily]);

  return variants.length > 0 ? (
    <Stack gap={4}>
      <Flex direction="row">
        <h4>
          {title} / {licenceTitle}
        </h4>
        <button
          sx={{ ml: 8 }}
          className="snipcart-add-item"
          data-item-id={id}
          data-item-price={0}
          data-item-name={title}
          data-item-url={'/products'}
          data-item-description={description}
          data-item-max-quantity="1"
          data-item-file-guid={guid.trim()}
          data-item-custom1-name={`${licenceTitle} Licence`}
          data-item-custom1-options={options}
          data-item-categories={categories}
        >
          Add to Cart
        </button>
      </Flex>
      <div>
        {variants.map((title, idx) => (
          <div key={title}>
            <span>{title}</span> :{' '}
            <span>
              {licence[`t${idx}_discount`] ? `£${licence[`t${idx}_discount`]} / was ` : null}
            </span>
            <span>£{licence[`t${idx}_value`]}</span>
          </div>
        ))}
      </div>
    </Stack>
  ) : null;
};
