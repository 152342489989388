import React, { useState, useCallback } from 'react';

import { ProductItem } from './product-item';
import { Stack } from '../grid';

import { web, desktop, app, ebook } from './constants';

const guidSuffix = process.env.GATSBY_ENVIRONMENT === 'live' ? '' : '_test';

export const ProductView = (props) => {
  const { slug, title, description, licence, typeface, isFamily, isFeebie, max = {} } = props;
  const maxWeb = parseInt(max['web']);
  const webVariants = web.filter((v, idx) => idx <= maxWeb);

  const maxApp = parseInt(max['app']);
  const appVariants = app.filter((v, idx) => idx <= maxApp);

  const maxDesktop = parseInt(max['desktop']);
  const desktopVariants = desktop.filter((v, idx) => idx <= maxDesktop);

  const maxEbook = parseInt(max['ebook']);
  const ebookVariants = ebook.filter((v, idx) => idx <= maxEbook);

  if (licence.licenceType === 'personal') {
    return (
      <ProductItem
        id={`${slug.current}-personal`}
        title={title}
        description={description}
        guid={props[`guid`]}
        variants={['1 User, 10000 Views/Month']}
        typeface={typeface}
        licenceTitle="Personal"
        licenceCategory="personal"
        licence={{ t0_value: 0 }}
        isFamily={isFamily}
      />
    );
  }

  return (
    <Stack gap={18}>
      {webVariants && (
        <ProductItem
          id={`${slug.current}-web`}
          title={title}
          description={description}
          guid={props[`guid_web${guidSuffix}`]}
          variants={webVariants}
          typeface={typeface}
          licenceTitle="Web"
          licenceCategory="web"
          licence={licence.web}
          isFamily={isFamily}
        />
      )}

      {desktopVariants && (
        <ProductItem
          id={`${slug.current}-desktop`}
          title={title}
          description={description}
          guid={props[`guid_desktop${guidSuffix}`]}
          variants={desktopVariants}
          typeface={typeface}
          licenceTitle="Desktop"
          licenceCategory="desktop"
          licence={licence.desktop}
          isFamily={isFamily}
        />
      )}

      {appVariants && (
        <ProductItem
          id={`${slug.current}-app`}
          title={title}
          description={description}
          guid={props[`guid_app${guidSuffix}`]}
          variants={appVariants}
          typeface={typeface}
          licenceTitle="App"
          licenceCategory="app"
          licence={licence.app}
          isFamily={isFamily}
        />
      )}

      {ebookVariants && (
        <ProductItem
          id={`${slug.current}-ebook`}
          title={title}
          description={description}
          guid={props[`guid_ebook${guidSuffix}`]}
          variants={ebookVariants}
          typeface={typeface}
          licenceTitle="E-Book"
          licenceCategory="ebook"
          licence={licence.ebook}
          isFamily={isFamily}
        />
      )}
    </Stack>
  );
};
