import React from 'react';
import PropTypes from 'prop-types';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { StaticImage } from 'gatsby-plugin-image';

import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

function SEO({ title, description, lang, meta, keywords, image, image_alt }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription = description || (data.site && data.site.description) || '';
        const siteTitle = (data.site && data.site.title) || '';

        const ogImages =
          image && image_alt
            ? [
                {
                  url: image.asset.localFile.childImageSharp.fixed.src,
                  width: image.asset.localFile.childImageSharp.fixed.width,
                  height: image.asset.localFile.childImageSharp.fixed.height,
                  alt: image_alt,
                },
              ]
            : [];

        return (
          <>
            <Helmet htmlAttributes={{ lang }} />
            <GatsbySeo
              title={`${title} | ${siteTitle}`}
              description={metaDescription}
              twitter={{
                handle: '@foundryfive',
                site: '@site',
                cardType: 'summary_large_image',
              }}
              openGraph={{
                title: `${title} | Foundry 5`,
                description: metaDescription,
                images: ogImages,
              }}
            />
          </>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
      title
      description
      # keywords
    }
  }
`;

/*

<Helmet
        htmlAttributes={{ lang }}
        title={title}
        titleTemplate={title === siteTitle ? '%s' : `%s | ${siteTitle}`}
        meta={[
          {
            name: 'description',
            content: metaDescription,
          },
          {
            property: 'og:title',
            content: title,
          },
          {
            property: 'og:description',
            content: metaDescription,
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:title',
            content: title,
          },
          {
            name: 'twitter:description',
            content: metaDescription,
          },
        ]
          .concat(
            keywords && keywords.length > 0
              ? {
                  name: 'keywords',
                  content: keywords.join(', '),
                }
              : []
          )
          .concat(meta)}
      />
    );
 

*/
